///include /assets/js/app/p/disabled-pages.js
///include /assets/js/app/20i/services-info.js

"use strict"

class SimpleService {
    /**
     *
     * @param {?string} service_type
     * @param {?string} service_id
     * @param {?string} oid
     * @returns {SimpleService}
     */
    static initial(service_type, service_id, oid) {
        this.inst = new SimpleService(service_type, service_id, oid)
        return this.inst
    }
    /**
     *
     * @param {?string} service_type
     * @param {?string} service_id
     * @param {?string} oid
     */
    constructor(service_type, service_id, oid) {
        this.oid = oid
        this.serviceType = service_type
        this.serviceId = service_id
        this.loadingName = false
        /** @type {?string} */
        this._discoveredHeaderName = null
    }
    get discoveredHeaderName() {
        return this._discoveredHeaderName
    }
    set discoveredHeaderName(v) {
        sessionStorage[`name:${this.serviceType}:${this.serviceId}`] = v
        this._discoveredHeaderName = v
    }
    /** @type {?string} */
    get headerName() {
        if(!this.loadingName) {
            this.loadingName = true
            if(sessionStorage[`name:${this.serviceType}:${this.serviceId}`]) {
                this._discoveredHeaderName =
                    sessionStorage[`name:${this.serviceType}:${this.serviceId}`]
            }
            switch(this.serviceType) {
                case "package":
                    $.ajax(
                        `/a/${this.serviceType}/${this.serviceId}/names`
                    ).then(
                        result => this.discoveredHeaderName = result[0]
                    )
                    break
                case "domain":
                    $.ajax(
                        `/a/${this.serviceType}/${this.serviceId}/name`
                    ).then(
                        result => this.discoveredHeaderName = result
                    )
                    break
                default:
                    break
            }
        }
        return this.discoveredHeaderName
    }
}

/**
 * Covers actual breadcrumbs as well as general top-of-page links
 */
class BreadcrumbsWidget {
    /**
     *
     * @param {?string} service_type
     * @param {?string} service_id
     * @param {?string} oid
     * @param {?string} theme
     */
    init(service_type, service_id, oid, theme) {
        this.view = new Vue({
            el: "#all-breadcrumbs",
            name: "breadcrumbs",
            data() {
                return {
                    disabledPages: DisabledPages.inst,
                    loaded: {
                        /** @type {?number} */
                        packagesCount: null,
                    },
                    loading: {
                        packagesCount: false,
                    },
                    service:
                        SimpleService.initial(service_type, service_id, oid),
                    settings: typeof SharedSettings !== "undefined" ?
                        SharedSettings.inst :
                        null,
                    get headerName() {
                        return this.service.headerName
                    },
                    get initialBreadcrumbs() {
                        if(theme && this.sbIsEnabled) {
                            if(this.settings && this.settings.hasBilling) {
                                return [
                                    {
                                        href: "/",
                                        text: translation.get("global_home"),
                                    },
                                    {
                                        href: "/manage",
                                        text: translation.get("global_client_area"),
                                    },
                                    this.serviceLink,
                                ]
                            } else {
                                return [
                                    {
                                        href: "/",
                                        text: translation.get("global_home"),
                                    },
                                    this.serviceLink,
                                ]
                            }
                        } else if(
                            this.service.serviceType == "domain" ||
                            (this.packagesCount || 0) > 1
                        ) {
                            return [
                                {
                                    href: "/",
                                    text: translation.get("global_home"),
                                },
                                this.serviceLink,
                            ]
                        } else {
                            return [
                                {
                                    href: this.serviceLink.href,
                                    text: translation.get("global_home"),
                                },
                            ]
                        }
                    },
                    get packagesCount() {
                        if(!this.loading.packagesCount) {
                            this.loading.packagesCount = true
                            if(typeof sessionStorage.packagesCount !== "undefined") {
                                this.loaded.packagesCount =
                                    +sessionStorage.packagesCount
                            }
                            $.ajax({
                                url: `/a/package`,
                                method: "head",
                            }).done((data, textStatus, jqXHR) => {
                                const xcl = jqXHR.getResponseHeader("X-Collection-Length")
                                if(xcl !== null) {
                                    this.loaded.packagesCount = +xcl
                                }
                            })
                        }
                        return this.loaded.packagesCount
                    },
                    get sbIsEnabled() {
                        return(
                            this.settings &&
                            this.settings.isOpen
                        )
                    },
                    get serviceLink() {
                        if(this.service.serviceType == "domain") {
                            return {
                                href: `/domain/${this.service.oid}`,
                                text: this.headerName || translation.get("global_domain_overview"),
                            }
                        } else {
                            return {
                                href: `/services/${this.service.oid}/service-overview`,
                                text: this.headerName || translation.get("global_service_overview"),
                            }
                        }
                    },

                    /**
                     *
                     * @param {string} href
                     * @returns {boolean}
                     */
                    isCurrentPage(href) {
                        return href == location.pathname
                    },
                    /**
                     *
                     * @param {string} href
                     * @returns {boolean}
                     */
                    isDisabledPage(href) {
                        return !!this.disabledPages.matches(href)
                    },

                    servicesInfo: new ServicesInfo(),
                    packageData: null,
                    get hasAvailableVps() {
                        return !!this.servicesInfo?.byType?.managed_vps?.length
                    },
                    isFavourite: false,
                    user: User.inst,
                }
            },
            computed: {
                pkg() {
                    return this.packageData;
                },
                editLink() {
                    return `/services/${this.service.oid}/edit`
                },
                manageLink() {
                    return `/services/${this.service.oid}/service-overview`
                },
                diagnosticsLink() {
                    return `/services/${this.service.oid}/auto-diagnostics`
                },
                resendWelcomeEmailLink() {
                    return `/services/${this.service.oid}/email/resend-welcome`
                },
                stackUserLoginLink() {
                    if(
                        this.pkg.data.stackUsers &&
                        this.pkg.data.stackUsers[0]
                    ) {
                        const ref = this.pkg.data.stackUsers[0]
                        const prefix =
                            ref.replace(/^stack-user:/, "/stackUser/")
                        return `${prefix}/login`
                    } else {
                        return null
                    }
                },
                transferLink() {
                    return `/services/${this.service.oid}/transfer-service`
                },
                splitPackageLink() {
                    return `/services/${this.service.oid}/split-package`
                },
            },
            methods: {
                enable(enable) {
                    if(confirm(`Are you sure you want to ${enable ? 'enable' : 'disable'} this package? All packages using this domain will be effected.`)) {
                        if(enable) {
                            $.ajax({
                                method: "post",
                                url: `/x/package/${this.service.oid}/userStatus`,
                                data: {
                                    includeRepeated: +true,
                                    subservices: {
                                        default: +true
                                    },
                                },
                            }).then(
                                () => {
                                    if(this.pkg.data) {
                                        this.pkg.data.enabled = true
                                        if(!window['bulkNotifications']) {
                                            ShowNotification.success(
                                                "Successfully enabled package, please allow 30 minutes for this to take effect."
                                            )
                                        }
                                    }
                                },
                                () => {
                                    ShowNotification.fail(`Could not enable package`)
                                }
                            )
                        } else {
                            $.ajax({
                                method: "post",
                                url: `/x/package/${this.service.oid}/userStatus`,
                                data: {
                                    includeRepeated: +true,
                                    subservices: {
                                        default: +false
                                    },
                                },
                            }).then(
                                () => {
                                    if(this.pkg.data) {
                                        this.pkg.data.enabled = false
                                        if(!window['bulkNotifications']) {
                                            ShowNotification.success(
                                                "Successfully disabled package, please allow 30 minutes for this to take effect."
                                            )
                                        }
                                    }
                                },
                                () => {
                                    ShowNotification.fail(`Could not disable package`)
                                }
                            )
                        }
                    }
                },

                addFavourite(){
                    $.ajax({
                        method: "post",
                        url: `/a/favourites`,
                        data: {
                            package_id: this.pkg.data.id
                        }
                    }).done(r => {
                        if(r){
                            this.isFavourite = true
                        }
                    })
                },

                deleteFavourite(){
                    $.ajax({
                        method: "post",
                        url: `/a/favourites/delete`,
                        data: {
                            package_id: this.pkg.data.id
                        }
                    }).done(r => {
                        if(r){
                            this.isFavourite = false
                        }
                    })
                }
            },
            async mounted() {
                /**
                 * Handler to update the last item in breadcrumbs.
                 */
                bus.$on('breadcrumbs:update:last', value => {
                    const el = document.querySelector('.breadcrumb-item:last-child')
                    if (!el) return

                    if (typeof value === 'string') {
                        el.textContent = value
                    }

                    if (typeof value === 'object') {
                        const { style, content } = value
                        if (content) {
                            el.innerHTML = content
                        }

                        if (style) {
                            style.forEach(({ property, val }) => {
                                el[property] = val
                            })
                        }
                    }
                })

                if(
                    this.service &&
                    this.service.serviceType == "package" &&
                    this.service.oid
                ){
                    const result = await $.ajax({
                        method: "get",
                        url: `/o/package/${this.service.oid}`,
                        data: {
                            purpose: "web",
                            fields: [
                                "id",
                                "stackUsers",
                                "enabled",
                                "web.platform",
                                "web.hasSsl",
                                "web.hasPremiumSsl",
                                "web.hasFreeSsl"
                            ],
                            selector: {
                                id: this.servicesInfo?.id
                            },
                        },
                    })
                    this.packageData = { data: result };

                    $.ajax(`/a/favourites`)
                        .done(r => {
                            const filteredFavourites = r.filter(item => item.packageId == this.pkg.data.id)
                            this.isFavourite = !!(filteredFavourites && filteredFavourites.length)
                        })
                }
            },
        })
    }
}
